html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Sora';
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.subGoal {
  text-align: left;
}

.addAction {
  text-align: right;
}

.addAction button {
  font-size: 12px;
}

.actionsItem {
  text-align: left;
  border-left: 4px solid #3e98fb;
  background: #cfcecc;
  color: #000000a8;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 11px;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
}

.actionsItem.inProgress {
  background: #dbfada !important;
}

.actionsItem.completed {
  background: #6d6a73 !important;
  color: #fff !important;
}

.actionsMain {
  text-align: left;
  cursor: pointer;
}

.actionsMain b {
  margin-left: -11px;
  margin-bottom: 7px;
}

.actionsMain .it1 {
  text-decoration: underline;
}

.actionsMain .it2 {
  text-decoration: none;
}

/* .react-flow__node.selected {
  border-radius: 105px !important;
  border: 3px solid #E35249 !important;
} */

.selected-node {
  border: var(--border-focus-width) var(--border-focus-style) var(--border-focus-color) !important;
}

.main-goal {
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  align-items: center;
  position: relative;
  height: 100%;
}
.main-goal-edit {
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
  position: relative;
  height: 100%;
}

.contextIcon {
  border-radius: 8px;
  cursor: pointer;
}

.contextIcon.withTop {
  top: 6px;
}
.contextIcon:hover {
  background: #dadadc;
}

.react-flow__node:focus {
  outline: none;
}

.inputNodeMainGoalCompleted {
  font-family: "Gellix Semibold";
  width: 90%;
  outline: none;
  background: transparent !important;
  border: none;
  color: var(--color-text-primary) !important;
}

.inputNodeMainGoalNotCompleted {
  font-family: "Gellix Semibold";
  width: 90%;
  outline: none;
  background: transparent !important;
  border: none;
  color: white !important;
}

.inputNodeSubGoal {
  font-family: "Gellix Semibold";
  width: 90%;
  outline: none;
  background-color: #0984e3;
  border: none;
}

.listView {
  position: absolute;
  top: calc(100% + 4px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px var(--space-s) 0px;
  position: absolute;
  width: 300px;
  background: var(--color-background-secondary);
  box-shadow: 0px 0px 8px 8px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 12px;
}

.listView.withTop {
  top: 35px;
}

.listView:hover {
  cursor: auto;
}

.react-flow__node-enterAction {
  z-index: -1 !important;
}

.react-flow__node-tabAction {
  z-index: -1 !important;
}

.app-container {
  height: calc(100vh - 119px);
}

.dx-scheduler-appointment {
  background: #ffffff !important;
  border: 1px solid #7c8c9c !important;
  border-radius: 8px !important;
  color: #fff !important;
  box-shadow: none !important;
}

.dx-scheduler-appointment.dx-state-hover {
  box-shadow: none;
}

.dx-scheduler-appointment.dx-state-focused {
  border: 1px solid #e35249 !important;
  background-color: #fff !important;
  border-radius: 8px !important;
}

.dx-scheduler-appointment.dx-state-focused:not(.dx-scheduler-appointment-drag-source)::before {
  background-color: #fff !important;
  border-radius: 8px !important;
}

.dx-scheduler-date-table-scrollable.dx-scrollable.dx-visibility-change-handler.dx-scrollable-vertical.dx-scrollable-native.dx-scrollable-native-generic {
  background-color: #f1f5f7 !important;
}
.dx-scheduler-header-panel-empty-cell {
  width: 49px !important;
  border-right: none !important;
}
.dx-scheduler-all-day-table-cell {
  border-top: none !important;
  border-left: none !important;
}
.dx-scheduler-all-day-title {
  height: 100% !important;
  border-bottom: none !important;
  font-family: 'Gellix Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  color: #666666 !important;
  width: 75px !important;
  text-align: left !important;
  border-top: none !important;
}
.dx-scheduler-all-day-panel {
  background-color: unset !important;
  height: 100%;
  display: flex;
  overflow: hidden !important;
}
.dx-scheduler-all-day-panel .dx-scheduler-all-day-table-cell {
  border-bottom: none !important;
}
.dx-scheduler-time-panel {
  width: 75px !important;
}
.dx-scheduler-header-panel {
  display: none !important;
}

.dx-scheduler-time-panel-cell.dx-scheduler-cell-sizes-vertical {
  font-family: 'Gellix Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 130% !important;
  color: #666666 !important;
}

.dx-scheduler-header-tables-container {
  height: 100%;
  overflow: hidden !important;
  padding-top: 4px;
}

.dx-scheduler-header-panel-container {
  box-sizing: border-box;
  /* display: none !important; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  height: 100px;
  background: #f1f5f7;
  border: 5px solid #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -2px 0px;
  overflow: hidden !important;
}

.dx-scheduler-view-switcher {
  display: none !important;
}

.MuiBackdrop-root {
  background-color: #0c2340 !important;
  opacity: 0.5 !important;
}
.loaderbg {
  background-color: #e2ecf1 !important;
  opacity: 1 !important;
}
.addIcon {
  display: none;
}
.dx-scheduler-header.dx-widget {
  display: none;
}

.dx-scheduler-work-space {
  height: calc(100vh - 250px) !important;
  overflow: scroll !important;
  background-color: #fff !important;
  border: none !important;
}
