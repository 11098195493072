.dx-scheduler-header {
  background: #fff !important;
  border: 1px !important;
}
.dx-scheduler-container {
  border-left: 8px solid #fff !important;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 1px;
  gap: 50px;
  width: 100%;
  background: #fff;
  z-index: 1;
  position: relative;
  cursor: pointer;
  height: 70px;
}
.check-circle-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 100px;
  margin-right: 12px;
}

.check-one-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 100px;
  margin-right: 12px;
  position: relative;
}
.setting-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 100px;
  margin-right: 12px;
  position: relative;
}

.view-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 8px;
  position: absolute;
  height: 85px;
  width: 100px;
  left: -123px;
  top: -12px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
}
.view-selector div {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.view-selector div img {
  height: 30px;
}

.dx-scheduler-navigator
  .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
  background-color: #fff !important;
  border: none !important;
  color: #0c2340 !important;
  font-size: 16px !important;
  font-weight: 500;
}

.dx-scheduler-navigator
  .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected)
  .dx-icon {
  color: #0c2340 !important;
  font-size: 25px !important;
}

.dx-toolbar-items-container .dx-toolbar-before {
  margin-left: 36%;
}

.dx-scheduler-appointment.dx-scheduler-appointment-drag-source.dx-draggable-source {
  display: none;
}

.dx-draggable-clone.dx-draggable-dragging .dx-item.dx-scheduler-appointment::before {
  opacity: 1 !important;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5) !important;
}

.dx-draggable-clone.dx-draggable-dragging .dx-item.dx-scheduler-appointment{
  border-color: #e35249 !important;
}

.eventContainer {
  font-family: "Gellix Medium";
  display: flex;
  height: 100%;
  overflow: revert !important; 
}

.dx-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.eventContainer .circleIcon {
  margin-right: 5px;
}

.eventContainer:hover .moreOptionsIcon svg {
  color: #0c2340;
}

#list .dx-draggable {
  width: 100% !important;
}
#list .dx-card {
  border: none !important;
  border-radius: var(--radius-m) !important;
}

.actionContextMenuInCalendar {
  position: absolute;
  right: 0;
}

.dx-scheduler-appointment:has(.selectedEvent) {
  border: 1px solid #e35249 !important;
}

div .dx-scheduler-appointment:has(.completedEvent) {
  background: transparent !important;
  border: none !important;
}

.dx-scheduler-appointment:has(.completedEvent) > div > div > div > div {
  color: #666666 !important;
}

div .dx-scheduler-appointment:has(.externalEvent) {
  background: rgba(255, 255, 255, 0.5) !important;
  pointer-events: none !important;
  cursor: default !important;
  border-color: var(--color-background-secondary)
}

div .dx-scheduler-appointment:has(.externalEvent):hover {
  box-shadow: none !important;
  cursor: default !important;
  pointer-events: none !important;
}

.dx-scheduler-appointment:has(.externalEvent) > div > div > div > div {
  color: #666666 !important;
}

.MuiBadge-badge {
  top: 4px !important;
  right: 16px !important;
}

