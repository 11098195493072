@import '../../genericStylesSheet/variables.scss';

.title {
  margin-bottom: 8px !important;
  padding-top: 10px;
  color: #000000;
  width: 450px !important;
  font-family: $font-default-semibold-font-family !important;
  font-size: $font-default-semibold-font-size !important;
  line-height: $font-default-semibold-line-height !important;
}

.subTitle {
  width: 450px !important;
  font-family: $font-default-regular-font-family !important;
  font-size: $font-default-regular-font-size !important;
  line-height: $font-default-regular-line-height !important;
}

.goBack {
  width: 190px;
  height: 56px;
  background: #f3f3f3 !important;
  border-radius: 8px;
  z-index: 0;
  color: #000000 !important;
  float: left;
  text-transform: none !important;
  margin: 0 auto !important;
  display: inline !important;

  :focus {
    border: 2px solid red;
  }
}
.delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #0c2340 !important;
  border-radius: 1000px !important;
  color: #fff !important;
  z-index: 0;
  font-family: $font-default-medium-font-family !important;
  font-size: $font-default-medium-font-size !important;
  line-height: $font-default-medium-line-height !important;
  text-transform: none !important;
  width: 100% !important;
  height: 48px !important;
}

.goBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e5e5e5 !important;
  border-radius: 1000px !important;
  color: #0c2340 !important;
  z-index: 0;
  font-family: $font-default-medium-font-family !important;
  font-size: $font-default-medium-font-size !important; 
  line-height: $font-default-medium-line-height !important;
  text-transform: none !important;
  width: 100% !important;
  height: 48px !important;
  :focus {
    border: 2px solid "#FF4027";
  }
}

.delete:disabled {
  background: #9c9ca1 !important;
  color: #b5b5b8 !important;
}
