@import '../../genericStylesSheet//variables.scss';

.expiredActions {
  background-color: #dcf9da;
  border-left-width: 4px;
  border-left-color: #5696f1;
  border-left-style: solid;
  margin: $core-cx3;
  border-radius: $radius-s;
  padding: $space-xs $space-m;
}
.expiredABadge {
  align-items: 'start';
}
.expiredAOuterContainer {
  min-height: 200px;
  min-width: 400px;
}
.recentScheduleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 352px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #7c8c9c;
  border-radius: 8px !important;
  margin: 12px;
  border-radius: $radius-s;
  padding: $space-xs $space-m;
}
.actionAchieved {
  padding: 6px 16px !important;
  font-size: $font-size-m !important;
  margin-right: 13px !important;
  background-color: $color-action-primary-default !important;
  border-radius: 1000px !important;
  color: $color-scales-white-default !important;
  z-index: 0;
  font-weight: $font-weigth-medium;
  line-height: 130%;
  text-transform: none !important;
}
.actionAchievedTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  color: #666666;
  padding: 0px !important;
}
.recentScheduleDivider {
  margin-top: 15px !important;
}
.textUpperCase {
  text-transform: uppercase !important;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 130%;
  color: #666666;
}
.actionTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  color: #0c2340;
  padding: 0 !important;
  margin-left: -10px !important;
}
.rActionTextStyle {
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  color: #0c2340;
  padding: 0 !important;
  margin-left: -8px !important;
}
.externalCalendarContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 990px;
  height: 270px;
  background: $color-scales-white-default;
  .connectOutlookBtn {
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 32px;
    background: $color-action-primary-default;
    border: 1px solid $border-form-selected-color;
    border-radius: 1000px;
    color: $color-action-secondary-default;
    text-transform: none;
    margin-left: 54px;
    &:hover {
      background: $color-background-selected !important;
      color: $color-action-primary-default !important;
    }
  }
  .connectGoogleBtn {
    display: flex;
    align-items: center;
    width: 200px;
    height: 32px;
    background: $color-action-primary-default;
    border: 1px solid $border-form-selected-color;
    border-radius: 1000px;
    color: $color-action-secondary-default;
    text-transform: none;
    margin-left: 24px;
    &:hover {
      background: $color-background-selected !important;
      color: $color-action-primary-default !important;
    }
  }
}

.connectCalendarBtnContainer {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85% !important;
}
.connectCalendarGrid {
  align-self: center;
  width: 100%;
}

.connectCalendarText {
  width: 100%;
  font-style: normal;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 550 !important;
  font-size: 27px !important;
  line-height: 100%;
  color: #000000 !important;
  margin-left: 3px !important;
}

.connectEmailList {
  margin-top: 10px;
}
.tableHeader {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12.5px !important;
  line-height: 130% !important;
  color: $color-text-primary !important;
}
.selectedAction {
  background: $color-background-selected !important;
  border: 1px solid $border-form-selected-color !important;
  color: $color-text-primary !important;
}

.repeatBtnGroup {
  box-shadow: none !important;
  text-transform: none !important;
}
.repeatBtnGroup :global(button:nth-child(2)) {
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 51px;
  height: 24px;
  background: $color-scales-secondary-100;
  border-radius: 0px 12px 12px 0px;
  color: $color-text-primary;
}
.repeatBtnGroup :global(button:first-child) {
  padding: 8px 16px;
  align-items: center;
  padding: 4px 16px;
  width: 51px;
  height: 24px;
  background: $color-scales-secondary-100;
  border-radius: 12px 0px 0px 12px;
  color: $color-text-primary;
}
.calendarSettingContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.doneActionText {
  font-size: $font-h4-medium-font-size !important;
  font-family: $font-h4-medium-font-family !important;
  line-height: $font-h4-medium-line-height !important;
  color: $color-text-primary;
}
.doneActionContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.confirmText {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 100%;
  color: #0c2340;
  margin-bottom: 8px;
  margin-left: 2px;
}
.actionIcon {
  margin-left: -11px !important;
  margin-top: -5px !important;
}
.calendarIconView {
  margin-left: 15px !important;
  margin-top: 4px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #0c2340;
}

.calendarWarningTextContainer {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 90% !important;
  flex-direction: column;
  gap: 4px;
}

.calendarWarningText {
  width: 100%;
  font-style: normal;
  display: flex;
  align-items: center;
  font-family: $font-default-regular-font-family;
  font-size: 12px !important; 
  line-height: $font-default-regular-line-height;
  color: #000000 !important;
  margin-left: 3px !important;
}
