@font-face {
    font-family: 'Gellix Regular';
    src: url('./fonts/Gellix-Regular.woff2') format('woff2'),
         url('./fonts/Gellix-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gellix Bold';
    src: url('./fonts/Gellix-Bold.woff2') format('woff2'),
         url('./fonts/Gellix-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gellix Medium';
    src: url('./fonts/Gellix-Medium.woff2') format('woff2'),
         url('./fonts/Gellix-Medium.woff') format('woff');
    font-weight: 500; /* 500 is usually considered 'Medium' */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gellix SemiBold';
    src: url('./fonts/Gellix-SemiBold.woff2') format('woff2'),
         url('./fonts/Gellix-SemiBold.woff') format('woff');
    font-weight: 600; /* 600 is usually considered 'SemiBold' */
    font-style: normal;
  }