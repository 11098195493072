.colorPickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  position: absolute;
  left: 107%;
  background: #ffffff;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 4px;
  gap: 8px;

  // width: 64px;
  // height: 218px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  width: 60px;
  height: 180px;

  div:hover {
    border: 5px solid #FF4027;
    width: 46px;
    height: 46px;
  }
}
.item1 {
  width: 56px;
  height: 56px;

  /* Goal 2 */

  background: #5696f1;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 50%;
}
.item2 {
  width: 56px;
  height: 56px;

  background: #7ba369;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  border-radius: 50%;
}
.item3 {
  width: 56px;
  height: 56px;

  background: #d5af29;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  border-radius: 50%;
}
.item4 {
  /* Ellipse 26 */

  width: 56px;
  height: 56px;

  background: #a27272;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 50%;
}
.item5 {
  width: 56px;
  height: 56px;

  /* Goal 4 */

  background: #094d49;
  // border: 5px solid #e35249;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  border-radius: 50%;
}
.item6 {
  width: 56px;
  height: 56px;

  background: #cf1b92;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  border-radius: 50%;
}
.item7 {
  width: 56px;
  height: 56px;

  background: #501414;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 50%;
}
.item8 {
  width: 56px;
  height: 56px;

  background: #4e279f;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  border-radius: 50%;
}
.item9 {
  width: 56px;
  height: 56px;

  background: #d62f2f;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  border-radius: 50%;
}
