@import '../../genericStylesSheet/variables.scss';

.mobileWarningContainer {
    color: $color-text-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
    padding: 20px;
    background-image: url('../../../public/images/big_yy.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
.mobileWarningContainer h1 {
    color: $color-text-primary;
    font-size: $font-h1-bold-font-size;
    font-family: $font-h1-bold-font-family;
    line-height: $font-h1-bold-line-height;
    margin-bottom: 20px;
  }
  
.mobileWarningContainer p {
    margin: 8px;
    color: $color-text-primary;
    font-size: $font-default-regular-font-size;
    font-family: $font-default-regular-font-family;
    line-height: $font-default-regular-line-height;
  }

.mobileWarningContainer p a {
    color: #fe3b1f !important;
  }

.mobileWarningContainer h1 img {
    height: 48px;
    vertical-align: middle; 
}

@media screen and (max-width: 600px) {
    .mobileWarningContainer h1 {
        font-size: 32px;
      }
      .mobileWarningContainer h1 img {
        height: 32px;
    }
  }