.title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 24px !important;
  padding-top: 10px;
  color: #000000;
}
.secondaryLabel {
  /* Paragraph */

  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 150% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.actionContainer {
  display: inline !important;
}
.goBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e5e5e5 !important;
  border-radius: 1000px !important;
  color: #0c2340 !important;
  z-index: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-transform: none !important;
  width: 100% !important;

  :focus {
    border: 2px solid "#FF4027";
  }
}
.fromQueue {
  margin-left: 15px !important;
}
.delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #0c2340 !important;
  border-radius: 1000px !important;
  color: #fff !important;
  z-index: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-transform: none !important;
  width: 100% !important;
}

.delete:disabled {
  background: #9c9ca1 !important;
  color: #b5b5b8 !important;
}

.queueContainer {
  margin: 10px 14px !important;
  min-width: 500px;
}
.goalTitle {
  margin: 0px !important;
}
