@import '../../genericStylesSheet//variables.scss';

.customCalendarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px 0px;
  gap: 8px;
  margin-bottom: 20px;
  margin-top: -15px;
}
.customCalendarContainerForWeek {
  margin-left: 7% !important;
}

.weekContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  height: 54px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  width: 100%;
}
.dayCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
  gap: 4px;

  width: 48px;
  height: 54px;

  /* Inside auto layout */

  flex:x none;
  order: 0;
  flex-grow: 0;
}
.calendarDayToday {
  font-weight: bold !important;
}
.calendarDaySelected {
  font-weight: bold !important;
}
.dayName {
  width: 48px;
  height: 16px;

  font-family: $font-small-regular-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 16px;
  /* identical to box height, or 128% */

  text-align: center;

  /* color/text/primary */

  color: $color-text-primary;

  /* Inside to layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.dayNumber {
  font-family: $font-small-semibold-font-family;
  position: relative;
  width: 28px;
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  &:hover {
    background: $color-action-tertiary-hover;
    border-radius: 20px;
    :global(.dayNumberText) {
      color: $color-text-primary;
    }
  }
}

.calendarDayTodaySelected {
  background: #7c8c9c;
  border-radius: 28px;
  :global(.dayNumberText) {
    color: #fff;
  }
}

.dayNumberText {
  position: absolute;
  width: 12px;
  height: 16px;
  left: 8px;
  top: 6px;

  font-family: $font-small-semibold-font-family;
  font-size: $font-small-semibold-font-size;
  line-height: $font-small-semibold-line-height;

  text-align: center;

  /* color/text/secondary */

  color: $color-text-secondary;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  height: 32px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.prevIcon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: $color-text-primary;

  width: 32px;
  height: 32px;

  /* color/action/secondary/default */

  background: #ffffff;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.nextIcon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 32px;
  height: 32px;

  color: $color-text-primary;

  background: #ffffff;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.weekText {
  color: $color-text-primary;
  height: 21px;

  font-family: $font-default-medium-font-family;
  font-size: $font-default-medium-font-size;
  line-height: $font-default-medium-line-height;

  display: flex;
  align-items: center;
  text-align: center;

  /* color/text/primary */

  color: #0c2340;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
