@import '../../../genericStylesSheet//variables.scss';

.container {
  display: flex;
  height: 100%;
}

.reactFlow {
  background-color: #5f5d72;
}

.node {
  background-color: #222138;
  color: #fff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 9px 0px;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  // z-index: 99999;
}

.nodeDropzone {
  background-color: #ff0072;
  box-shadow: 0 0 9px 0 #ff0072;
  border-radius: 10px;
}

.handle {
  pointer-events: none;
  opacity: 0;
}

.sidebar {
  color: #120b0b;
  top: 5px;
  left: 5px;
  // z-index: 1000;
  padding: 10px;
  border: 1px solid #222138;
  border-radius: 15px;
}

.sidebarLabel {
  font-size: 14px;
  margin-bottom: 10px;
}

.sidebarNode {
  border-left: 4px solid #0000006b;
  background: #ccc;
  color: #0000007d;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 10px;
  text-align: left;
  padding-left: 10px;
  font-weight: 700;
}

.sidebarNode:hover {
  box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 9px 0px;
}
.dndnode {
  font-size: 12px;
  height: 20px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  border-color: #ff0072;
}
.headerContainer {
  font-family: $font-small-medium-font-family;
  font-size: $font-small-medium-font-size;
  line-height: $font-small-medium-line-height;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $space-s $space-m $space-s $space-m;
  gap: 8px;
  border-radius: $radius-l $radius-l 0 0;

  height: 18px;
  flex: 0;

  /* Inside auto layout */

  align-self: stretch;
  margin-bottom: 4px;

  p {
    font-family: $font-small-medium-font-family;
    font-size: $font-small-medium-font-size;
    line-height: $font-small-medium-line-height;
  }
}

.headerContainer span {
  width: 49px;
  height: 13px;

  /* identical to box height */

  /* Gray/60 */

  color: #6b6b71;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.headerContainer div {
  margin-top: -3px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  gap: 8px;
  width: 147px;
  height: 18px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-right: -5px;
}
.headerContainer button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 147px;
  height: 18px;

  /* Gray/5 */

  background: #f3f3f3;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  width: 115px;
  height: 24px;

  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/90 */

  color: #21212b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.headerContainer button:hover {
  background: #f3f3f3;
}

.actionInputContainer {
  width: 100%;
  input {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
  }
}

.actionInputContainer :global(.MuiInputBase-root) {
  padding: 0 !important;
  cursor: pointer;
}
.actionInputContainer .actionAdornment {
  height: 32px !important;
  padding: 4px !important;
  border-radius: $radius-m;
  cursor: pointer;
}
.actionInputContainer :global(.MuiInputAdornment-positionStart) {
  width: 6px;
  margin-left: 3px;
  margin-right: 16px;
  cursor: pointer;
}

.actionInputContainerReadOnly {
  background: rgb(255, 255, 255);
  height: 32px;
  border-radius: $radius-m;
  cursor: pointer;
  input {
    color: #000 !important;
    font-family: $font-small-semibold-font-size;
    font-size: $font-small-semibold-font-size;
    line-height: $font-small-semibold-line-height;
    caret-color: transparent;
    pointer-events: none;
  }
}

.actionInputContainerReadOnly :global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
  cursor: pointer;
  border: 1px solid $border-focus-color !important;
}
.draggableContainer {
  width: 100%;
}
.actionItemContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 32px;
  background-color: transparent !important;
  border-radius: $radius-m !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin-bottom: 4px;
  cursor: pointer;
}

.addActionBtn {
  padding: 2px !important;
  font-family: $font-small-medium-font-family;
  font-size: 9px !important;
  line-height: $font-small-medium-line-height;
  color: #0c2340 !important;
  text-transform: none !important;
  width: 100%;
  justify-content: flex-start !important;
}

.actionInputCompleted {
  border: 1px solid #d4d4d4 !important;
  background: $color-background-primary;
}
.actionInputCompleted :global(.MuiOutlinedInput-notchedOutline) {
  border-width: 0px !important;
}
.actionInputCompleted :global(.MuiInputBase-root-MuiOutlinedInput-root) {
  &:hover {
    border-color: transparent !important;
  }
}
// .startDragging {
//   border: 1px solid $border-focus-color;
//   border-radius: $radius-m !important;
// }

.marginContainer {
  width: 100%;
  padding: $space-s;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  min-height: 0; 
  height: 100%;
}
