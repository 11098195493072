@import '../../genericStylesSheet//variables.scss';

.queue {
  align-items: flex-end;
  background: $color-background-secondary;
  border-color: $border-white-outline-color;
  border-width: $border-width-s;
  border-style: $border-form-default-style;
  bottom: 0;
  box-align: end;
  box-flex: 0;
  box-ordinal-group: 3;
  box-orient: vertical;
  box-pack: end;
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-direction: column;
  gap: $space-s;
  height: 66px;
  isolation: isolate;
  justify-content: flex-end;
  padding: 0 $space-s $space-s 60px;
  // position: fixed;
  width: 100%;
  z-index: 2;

  span {
    font-size: $font-size-s;
    font-weight: $font-weigth-regular;
    order: 0;
    text-align: center;
  }
  .queueItems {
    display: flex;
    flex-direction: row-reverse;
    align-content: space-between;
    margin-right: 13px;
  }

  .queueItem {
    background: $color-background-primary;
    // color: $color-scales-white-default;
    position: 'relative';
    min-width: 180px;
    height: 44px;
    border-radius: $radius-m !important;
    align-content: space-between;
    display: flex;
    align-items: center;

    &:focus {
      border-color: $border-focus-color;
      border-width: $border-width-s;
      border-style: $border-form-default-style;
    }
  }
}
.headerContainer button:hover {
  background: $color-background-primary;
}

.actionInputContainer {
  color: $color-action-primary-default !important;
  width: 100%;
  background: #ffffff;
  input {
    font-weight: $font-weigth-regular;
    font-size: $font-size-s;
    line-height: 13px;
  }
}

.actionInputContainer :global(.MuiInputBase-root) {
  padding: 0 !important;
  height: 54px;
  align-items: start;
}

.actionInputContainer :global(.MuiInputAdornment-positionStart) {
  width: 18px;
  margin-top: 17px;
  margin-left: 5px;
}
.actionInputContainer :global(.MuiInputAdornment-positionEnd) {
  width: 18px;
  margin-top: 16px;
  margin-left: -8px;
  margin-right: 8px;
}
.actionInputContainerReadOnly {
  background: $color-background-primary;
  border-radius: $radius-m;
  border-color: $border-form-default-color;
  border-width: $border-width-s;
  border-style: $border-form-default-style;
  input {
    color: $color-text-primary !important;
    font-weight: $font-weigth-regular;
    font-size: $font-size-s;
    line-height: 13px;
  }
}
.actionInputContainerReadOnly :global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border: 2px solid $border-focus-color !important;
}
.actionInputContainerReadOnly :global(.MuiInputBase-input) {
  padding: 7.5px 7px 6px 0px;
  color: $color-scales-primary-900;
  font-weight: $font-weigth-semibold;
  font-size: $font-size-s;
  caret-color: transparent;
}
.dropZone {
  background: transparent;
  height: 29px;
  margin-top: -29px;
  position: inherit;
}

.dragover {
  background-color: $color-systems-focus-default;
  height: 20px;
}
.extraSpaceContainer {
  position: relative;
  width: 100%;
  margin-left: 5px;
  margin-right: 70px;
  margin-bottom: $space-s;
}
.normalContainer {
  position: relative;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
}

.queueInputControl {
  width: 100%;
  background: $color-background-primary;
  border-radius: $radius-m;
  border-color: $border-form-default-color;
  border-width: $border-width-s;
  border-style: $border-form-default-style;
  padding: $space-s 12px 12px $space-s !important;
  input {
    font-weight: $font-weigth-regular;
    font-size: $font-size-s;
    line-height: 13px;
    padding-left: $space-s;
  }
}
.queueInputControl :global(.MuiInputBase-root) {
  padding: 0 !important;
  background-color: $color-scales-white-default;
  height: 25px;
}
.queueInputControl :global(.MuiInputAdornment-positionStart) {
  width: 14px;
  padding-left: 0px !important;
}
:global(.MuiSvgIcon-root) {
  background-color: transparent !important;
}
.queueInputOverFlowControlContainer {
  width: 175px;
  border-radius: $radius-m;
  border-color: $border-action-color;
  border-width: 1px;
  height: 54px;
  margin-bottom: 10px;
  border-style: solid;
  margin-left: 7px;
}
.queueInputOverFlowControl {
  width: 100%;
  border-radius: $radius-m;
  border-color: $border-form-default-color;
  border-width: $border-width-s;
  border-style: $border-form-default-style;
  padding: $space-s 3px 22px 3px !important;
  margin: 1px 0px 2px $space-s;
  input {
    font-weight: $font-weigth-regular;
    font-size: $font-size-s;
    line-height: 13px;
    padding-left: $space-s;
  }
}
.queueInputOverFlowControl :global(.MuiInputBase-root) {
  padding: 0 !important;
  background-color: $color-scales-white-default;
  height: 25px;
  width: 157px;
}
.queueInputOverFlowControl :global(.MuiInputAdornment-positionStart) {
  width: 14px;
  padding-left: 0px !important;
}
.extraSpaceContainerOverFlow {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.queueItemCount {
  background: $color-scales-white-default;
  height: 40px;
  width: 40px;
  font-weight: 500;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 40px;
  margin-top: -3px;
  z-index: 1;
  cursor: pointer;
  svg {
    margin-left: -6px;
    padding-right: 3px;
  }
  span {
    font-size: $font-size-m;
    margin-left: -$space-s;
  }
}
.queueText {
  display: flex;
  flex-direction: column-reverse;
  font-size: $font-size-m;
  color: $color-text-default;
}
.normalContainerOverFlow {
  position: relative;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.actionInputContainerOverFlow {
  width: 100%;
  input {
    font-weight: $font-weigth-regular;
    font-size: $font-size-xs;
    line-height: 13px;
  }
}

.actionInputContainerOverFlow :global(.MuiInputBase-root) {
  padding: 0px 10px 0px 0px !important;
  height: 54px;
  align-items: start;
}

.actionInputContainerOverFlow :global(.MuiInputAdornment-positionStart) {
  width: 11px;
  margin-top: 13px;
  margin-left: 2px;
}
.actionInputContainerOverFlow :global(.MuiInputAdornment-positionEnd) {
  width: 18px;
  margin-top: 17px;
  margin-left: 5px;
}
.actionInputContainerReadOnlyOverFlow {
  background: $color-background-primary;
  border-radius: $radius-m;
  border-color: $border-form-default-color;
  border-width: $border-width-s;
  border-style: $border-form-default-style;
  width: 180px;
  margin-bottom: 10px !important;
  input {
    color: $color-text-primary !important;
    font-weight: $font-weigth-regular;
    font-size: $font-size-s;
    line-height: 13px;
  }
}
.actionInputContainerReadOnlyOverFlow :global(.MuiInputBase-input) {
  padding: 4.5px 6px 3px 0px;
  color: $color-text-primary;
  font-weight: 600;
  font-size: 12.5px;
}
.actionInputContainerReadOnlyOverFlow :global(.Mui-focused) {
  cursor: none;
}

.queueOverflow {
  background-color: $color-scales-white-default;
  position: absolute;
  left: 8px;
  bottom: 65px;
  width: 204px;
  height: 464px;
  display: flex;
  flex-direction: column-reverse;
  padding: 3px 0px 5px 2px;
  box-shadow: 0px 0px 8px 8px $color-scales-secondary-200,
    0px $space-s $space-s $color-scales-secondary-200;
  .addIcon {
    position: absolute;
    top: 5px;
    right: 0px;
  }
}
.queueItemsArea {
  height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.queueArrow {
  display: flex;
  align-items: baseline;
  position: absolute;
  z-index: -1;
  .queueArrowText {
    font-family: $font-small-regular-font-family;
    font-size: $font-small-regular-font-size;
    font-weight: $font-small-regular-font-weight;
    line-height: $font-small-regular-line-height;
    color: #40566e;
    margin-right: 10px;
  }
}
.firstElementOfQueue {
  top: -20px;
}

.firstInputAdornment {
  margin-right: 4px !important;
}

.currentlyDraggedQueueItem {
  background: black !important;
}

