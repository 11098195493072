@import '../theme/variables.scss';
@import '../genericStylesSheet/variables.scss';

.calendarInputControl {
  background: #fff;
  height: 25px;
  width: 70%;
  border-radius: 4px;
  input {
    font-size: 12.5px;
    line-height: 130%;
    color: #0c2340 !important;
    padding-left: 4px;
  }
}

.calendarInputControl :global(.MuiOutlinedInput-root) {
  height: 24px !important;
}

.customDataCellDiv {
  background: $color-background-secondary;
  height: 100%;
  width: 100%;
  pointer-events: all;
  // border-bottom-color: $border-calendar-color;
  // border-bottom-width: $border-calendar-width;
  // border-bottom-style: $border-calendar-style;
  // border-spacing: 0;
}

.calendarInputControlReadOnly {
  background: #fff;
  border-width: 0;
  border: 0;
  input {
    font-weight: 600;
    font-size: 12.5px;
    line-height: 130%;
    color: #0c2340 !important;
    caret-color: transparent;
    padding-top: 11px;
    border: none;
  }
  input:focus {
    outline: none !important;
    border: 'solid 2px #E35249';
  }
}
.calendarInputControlReadOnly :global(.MuiOutlinedInput-notchedOutline) {
  border: none !important;
}
.calendarInputControlCompleted {
  background-color: transparent !important;
  border-width: 0;
  border: none;
  pointer-events: none;
  input {
    color: #21212b !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    caret-color: transparent;
    padding-top: 12px;
    border: none;
    caret-color: transparent;
    pointer-events: none;
  }
  fieldset {
    border: none;
  }
}

.eventText {
  width: 100%;
  font-family: $font-small-medium-font-family;
  font-size: $font-small-medium-font-size;
  line-height: $font-small-medium-line-height;
  color: #0c2340;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-wrap: break-word;
word-wrap: break-word;
white-space: normal;
flex-direction: row;
justify-content: flex-start;
}

.eventIcon {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.eventComponentText {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

