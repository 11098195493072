.setGoalBtn {
  display: flex;
  width: 160px;
  height: 56px;
  background: #f3f3f3 !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center !important;
  text-align: center !important;
  color: #21212b !important;
  text-transform: none !important;
  position: relative !important;
  bottom: 140px !important;
  z-index: 999;
}
.setGoalBtnContainer {
  display: flex;
  justify-content: center;
}
