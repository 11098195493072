.edgeButton {
  cursor: pointer;
  pointer-events: all;
  stroke: #bbb;
  fill: white;
}

.edgeButton:hover {
  fill: #f5f5f5;
}

.edgeButtonText {
  pointer-events: none;
  user-select: none;
  fill: #bbb;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #b5b5b8;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b5b5b8;
}

.edgeButtonSym {
  pointer-events: none;
  user-select: none;
  fill: #bbb;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b5b5b8;
}

.edgePath {
  fill: none;
  stroke: var(--color-text-primary);
  stroke-width: 1;
  stroke-dasharray: 4 4;
}

.placeholderPath {
  stroke-width: 1;
  stroke-dasharray: 4 4;
  stroke: var(--color-text-tertiary);
  fill: none;
}
