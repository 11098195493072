@import '../../theme/variables';
@import '../../genericStylesSheet/variables.scss';

.header {
  align-items: center;
  display: flex;
  padding: 16px;
  background: #e2ecf1;
}
.logo {
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 8px;
  height: 24px;
  order: 0;
  padding: 0;
  width: 100%;
  z-index: 0;
  font-family: $font-default-medium-font-family;
}
.status {
  align-items: center;
  border-radius: 0 0 $space-m $space-m;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 8px;
  height: 32px;
  margin: 0 auto;
  order: 1;
  padding: 0;
  z-index: 1;
}
.status-data {
  padding: 0px !important;
  width: 195px !important;
  height: 32px !important;
  background: #ffffff !important;
  border-radius: $radius-xxl !important;
}
.ellipse {
  margin-right: 2.67% !important;
  width: 16px !important;
}
.status-text {
  font-family: $font-small-regular-font-family;
  font-size: $font-small-regular-font-size;
  line-height: $font-small-regular-line-height;
  color: #0c2340;
  text-transform: none !important;
  margin-right: 8% !important;
}
.undoredo {
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 2px;
  order: 1;
  padding: 0;
}
.undo {
  align-items: center;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 8px;
  order: 0;
  padding: 4px;
}
.redo {
  align-items: center;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 8px;
  order: 1;
  padding: 4px;
}
.myGoalsBtn {
  padding: $space-m $space-xxl;
  width: 120px;
  height: 32px;
  background: $color-scales-white-default !important;
  border-radius: 1000px !important;
  font-family: $font-small-regular-font-family !important;
  font-size:  $font-small-regular-font-size !important;
  line-height:  $font-small-regular-line-height !important;
  color: $color-scales-primary-900 !important;
  text-transform: none !important;
  margin-left: 20px !important;

  img {
    margin-right: 10%;
  }
}

.hamburger {
  cursor: pointer;
}

.menuHeading {
  font-family: $font-h4-regular-font-family;
  font-size: $font-h4-regular-font-size;
  line-height: $font-h4-regular-line-height;
  padding: 18px 20px 12px 20px;
}

.menuItem {
  font-family: $font-default-medium-font-family;
  font-size: $font-default-medium-font-size;
  line-height: $font-default-medium-line-height;
  padding: 12px 20px 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  &:hover {
    background: $color-background-selected;
    cursor: pointer;
  }
}

.settingsDropdown {
  width: 220px;
}

