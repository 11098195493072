@import '../../genericStylesSheet//variables.scss';

.node {
  background: $color-scales-goal-a-default;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 16px;
  gap: 8px;
  width: 289px;
  height: 36px;
}

.homeNode {
  border: 1px solid $color-scales-goal-a-default;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding: 12px;
  width: 200px;
  text-align: center;
  background: $color-scales-goal-a-default;
  cursor: pointer;
  line-height: 1.2;
  border-radius: 100px;
  font-size: 20px;
  font-weight: normal;
  font-family: 'Sora';
}

.mainGoalNode {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.mainGoalLabel {
  color: $color-scales-white-default !important;
  font-family: $font-default-semibold-font-family;
  font-size: 14px;
  line-height: $font-default-semibold-line-height;
}

.subGoalLabel {
  font-family: $font-default-semibold-font-family;
  font-size: 12.5px;
  line-height: 1;
  color: #0c2340;
}

.childNode {
  background: #ffffff;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 16px;
  gap: 8px;
  width: 289px;
  height: 36px;
}

.goalCompleted {
  background: #ffffff !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.goalCompletedLabel {
  color: $color-action-primary-default !important;
}

.node:hover,
.childNode:hover {
  //background: $color-background-primary;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.placeholder {
  width: 200px;
  background: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  box-shadow: none;
}

.actionNode {
  visibility: hidden;
}
.goalNode {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0px !important;
  display: inline-block !important;
}
.goalLabel {
  width: 152px;
  font-family: $font-default-semibold-font-family;
  font-size: $font-default-semibold-font-size;
  line-height: $font-default-semibold-line-height;
  text-align: center;
  margin-top: 8px;
}
.goalNodeContainer {
  border-radius: 4px;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goalNodeContainer:hover {
  .contextIcon {
    display: block;
    cursor: pointer;
  }
}
.goalNodeContainer .contextIcon {
  position: absolute;
  width: 64px;
  height: 32px;
  left: 150px;
  top: 6px;
  border-radius: 8px;
  display: none;
  cursor: pointer;
  &:hover {
    background: $color-background-primary;
  }
}
.goalLabel2 {
  margin-left: 36px;
  margin-right: 36px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.goalBigLabel {
  height: 53px;
}

.rightCursor {
  background: transparent;
  position: absolute;
  height: 270px;
  width: 150px;
  display: block;
  z-index: -1;
  left: 85px;
  top: -38px;
}

.leftCursor {
  background: transparent;
  position: absolute;
  height: 270px;
  width: 150px;
  display: block;
  z-index: -1;
  right: 100px;
  top: -38px;
}

.goalNodeMainContainer {
  display: flex;
}

.goalNodeContainerBox {
  width: 235px;
  display: flex;
}

.actionsCount {
  font-family: $font-default-medium-font-family;
  font-size: $font-default-medium-font-size;
  line-height: $font-default-medium-line-height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 28px;
  height: 28px;
  right: 7px;
  background: $color-background-selected;
  border-radius: 64px;
}

.mainGoalCont:hover {
  :global(.addIcon) {
    display: block !important;
  }
}
