// Do not edit directly
// Generated on Sat, 15 Apr 2023 01:10:06 GMT

$core-c-half: 2px;
$core-c: 4px;
$core-cx2: 8px;
$core-cx3: 12px;
$core-cx4: 16px;
$core-cx5: 20px;
$core-cx6: 24px;
$core-cx7: 28px;
$core-cx8: 32px;
$core-cx9: 36px;
$core-cx10: 40px;
$core-cx12: 48px;
$core-cx14: 56px;
$core-cx45: 180px;
$core-cx60: 240px;
$core-cx69: 276px;
$core-cx100: 400px;
$core-radial: 10px;
$main-font: Gellix TRIAL;
$color-scales-primary-0: #fcfdfd;
$color-scales-primary-100: #f5f6f7;
$color-scales-primary-200: #d7dbe0;
$color-scales-primary-300: #b9c1ca;
$color-scales-primary-400: #9ba6b3;
$color-scales-primary-500: #7c8c9c;
$color-scales-primary-600: #5e7185;
$color-scales-primary-700: #40566e;
$color-scales-primary-800: #223c57;
$color-scales-primary-900: #0c2340;
$color-scales-primary-1000: #021120;
$color-scales-white-default: #ffffff;
$color-scales-focus-0: #fff9f8;
$color-scales-focus-100: #ffecea;
$color-scales-focus-200: #ffd9d4;
$color-scales-focus-300: #ffc6bf;
$color-scales-focus-400: #ffa093;
$color-scales-focus-500: #ff7a68;
$color-scales-focus-600: #ff4027;
$color-scales-focus-700: #d93621;
$color-scales-focus-800: #b22d1b;
$color-scales-focus-900: #7f2013;
$color-scales-focus-1000: #4c130c;
$color-scales-goal-a-light: #85bdef;
$color-scales-goal-a-default: #0b7be0;
$color-scales-goal-a-dark: #08569d;
$color-scales-goal-b-light: #afc59e;
$color-scales-goal-b-default: #608c3d;
$color-scales-goal-b-dark: #43622b;
$color-scales-goal-c-light: #afc59e;
$color-scales-goal-c-default: #608c3d;
$color-scales-goal-c-dark: #43622b;
$color-scales-goal-d-light: #cab9aa;
$color-scales-goal-d-default: #957356;
$color-scales-goal-d-dark: #68513c;
$color-scales-goal-e-light: #ac9fd3;
$color-scales-goal-e-default: #5a3fa6;
$color-scales-goal-e-dark: #3f2c74;
$color-scales-goal-f-light: #ef95b8;
$color-scales-goal-f-default: #de2b71;
$color-scales-goal-f-dark: #9b1e4f;
$color-scales-goal-g-light: #dbb5ac;
$color-scales-goal-g-default: #b66b59;
$color-scales-goal-g-dark: #7f4b3e;
$color-scales-goal-h-light: #c58b88;
$color-scales-goal-h-default: #8c1712;
$color-scales-goal-h-dark: #62100d;
$color-scales-goal-i-light: #c09ae5;
$color-scales-goal-i-default: #8136cc;
$color-scales-goal-i-dark: #5a268f;
$color-scales-goal-j-light: #8ecec3;
$color-scales-goal-j-default: #1d9d88;
$color-scales-goal-j-dark: #146e5f;
$color-scales-goal-k-light: #a7b088;
$color-scales-goal-k-default: #4f6111;
$color-scales-goal-k-dark: #37440c;
$color-scales-goal-l-light: #9cb3a8;
$color-scales-goal-l-default: #3a6752;
$color-scales-goal-l-dark: #294839;
$color-scales-goal-m-light: #c589b1;
$color-scales-goal-m-default: #8c1463;
$color-scales-goal-m-dark: #620e45;
$color-scales-goal-n-light: #c59e88;
$color-scales-goal-n-default: #8c3e12;
$color-scales-goal-n-dark: #622b0d;
$color-scales-goal-o-light: #a5a5a8;
$color-scales-goal-o-default: #4b4b52;
$color-scales-goal-o-dark: #353539;
$color-scales-goal-p-light: #cedaa7;
$color-scales-goal-p-default: #9db550;
$color-scales-goal-p-dark: #6e7f38;
$color-scales-secondary-0: #fcfcfc;
$color-scales-secondary-100: #f2f2f2;
$color-scales-secondary-200: #e5e5e5;
$color-scales-secondary-300: #cccccc;
$color-scales-secondary-400: #b2b2b2;
$color-scales-secondary-500: #999999;
$color-scales-secondary-600: #808080;
$color-scales-secondary-700: #666666;
$color-scales-secondary-800: #4d4d4d;
$color-scales-secondary-900: #333333;
$color-scales-secondary-1000: #1a1a1a;
$color-scales-ui-0: #f8fcff;
$color-scales-ui-100: #eef7fe;
$color-scales-ui-200: #e4eff8;
$color-scales-ui-300: #c3d8ec;
$color-scales-ui-400: #97bbde;
$color-scales-ui-500: #6d9dd0;
$color-scales-ui-600: #4275ad;
$color-scales-ui-700: #36608e;
$color-scales-ui-800: #2a4b6f;
$color-scales-ui-900: #1e354f;
$color-scales-ui-1000: #122030;
$color-scales-extracolours-top: #f1f5f7;
$color-scales-extracolours-bottom: #e2ecf1;
$color-scales-extracolors-dropit: #fff9f8;
$color-scales-extracolors-for-actions: #f1f5f7;
$color-scales-extracolors-backdrop: rgba(4, 33, 64, 0.5);
$color-system-green-50: #e4f8e7;
$color-system-green-light: #80e6a8;
$color-system-green-default: #00cd52;
$color-system-green-dark: #008f39;
$color-system-red-50: #feebee;
$color-system-red-light: #e09396;
$color-system-red-default: #c1272d;
$color-system-red-dark: #871b20;
$color-system-yellow-50: #fffbeb;
$color-system-yellow-default: #f59e0b;
$color-system-yellow-dark: #b45309;
$color-goals-a-light: #85bdef;
$color-goals-a-default: #0b7be0;
$color-goals-a-dark: #08569d;
$color-goals-b-light: #afc59e;
$color-goals-b-default: #608c3d;
$color-goals-b-dark: #43622b;
$color-goals-c-light: #afc59e;
$color-goals-c-default: #608c3d;
$color-goals-c-dark: #43622b;
$color-goals-d-light: #cab9aa;
$color-goals-d-default: #957356;
$color-goals-d-dark: #68513c;
$color-goals-e-light: #ac9fd3;
$color-goals-e-default: #5a3fa6;
$color-goals-e-dark: #3f2c74;
$color-goals-f-light: #ef95b8;
$color-goals-f-default: #de2b71;
$color-goals-f-dark: #9b1e4f;
$color-goals-g-light: #dbb5ac;
$color-goals-g-default: #b66b59;
$color-goals-g-dark: #7f4b3e;
$color-goals-h-light: #c58b88;
$color-goals-h-default: #8c1712;
$color-goals-h-dark: #62100d;
$color-goals-i-light: #c09ae5;
$color-goals-i-default: #8136cc;
$color-goals-i-dark: #5a268f;
$color-goals-j-light: #8ecec3;
$color-goals-j-default: #1d9d88;
$color-goals-j-dark: #146e5f;
$color-goals-k-light: #a7b088;
$color-goals-k-default: #4f6111;
$color-goals-k-dark: #37440c;
$color-goals-l-light: #9cb3a8;
$color-goals-l-default: #3a6752;
$color-goals-l-dark: #294839;
$color-goals-m-light: #c589b1;
$color-goals-m-default: #8c1463;
$color-goals-m-dark: #620e45;
$color-goals-n-light: #c59e88;
$color-goals-n-default: #8c3e12;
$color-goals-n-dark: #622b0d;
$color-goals-o-light: #a5a5a8;
$color-goals-o-default: #4b4b52;
$color-goals-o-dark: #353539;
$color-goals-p-light: #cedaa7;
$color-goals-p-default: #9db550;
$color-goals-p-dark: #6e7f38;
$color-background-primary: #ffffff;
$color-background-primary-50: #ffffff7f;
$color-background-secondary: #f1f5f7;
$color-background-gradient: #000000;
$color-background-selected: #e4eff8;
$color-background-imported-action-default: #999999;
$color-background-imported-action-hover: #808080;
$color-background-dark: #333333;
$color-text-primary: #0c2340;
$color-text-secondary: #666666;
$color-text-tertiary: #7c8c9c;
$color-text-quaternary: #b2b2b2;
$color-text-inverse: #ffffff;
$color-text-default: #40566e;
$color-text-divider: #f2f2f2;
$color-action-primary-default: #0c2340;
$color-action-primary-hover: #223c57;
$color-action-primary-inverse: #ffffff;
$color-action-secondary-default: #ffffff;
$color-action-secondary-hover: #e5e5e5;
$color-action-secondary-inverse: #0c2340;
$color-action-disable-bg: #b2b2b2;
$color-action-disable-fg: #666666;
$color-action-tertiary-default: #7c8c9c;
$color-action-tertiary-hover: #e5e5e5;
$color-action-tertiary-hover-fg: #0c2340;
$color-action-quaternary-default: #f2f2f2;
$color-systems-success-50: #e4f8e7;
$color-systems-success-light: #80e6a8;
$color-systems-success-default: #00cd52;
$color-systems-success-dark: #008f39;
$color-systems-error-50: #feebee;
$color-systems-error-light: #e09396;
$color-systems-error-default: #c1272d;
$color-systems-error-dark: #871b20;
$color-systems-focus-default: #ff4027;
$color-systems-focus-light: #fff9f8;
$color-systems-warning-50: #fffbeb;
$color-systems-warning-dark: #b45309;
$color-systems-warning-default: #f59e0b;
$line-height-s: 100%;
$line-height-m: 130%;
$line-height-l: 150%;
$line-height-xl: 175%;
$font-size-xs: 9px;
$font-size-s: 12.5px;
$font-size-m: 16px;
$font-size-l: 21px;
$font-size-xl: 27;
$font-size-xxl: 60px;
$font-weigth-bold: Bold;
$font-weigth-semibold: SemiBold;
$font-weigth-medium: Medium;
$font-weigth-regular: Regular;
$border-width-s: 1px;
$radius-circle: 1000px;
$radius-s: 4px;
$radius-m: 8px;
$radius-l: 12px;
$radius-xl: 16px;
$radius-xxl: 24px;
$font-h1-regular-font-family: "Gellix Regular";
$font-h1-regular-font-size: 60px;
$font-h1-regular-line-height: 100%;
$font-h1-regular-font-weight: Regular;
$font-h1-medium-font-family: "Gellix Medium";
$font-h1-medium-font-size: 60px;
$font-h1-medium-line-height: 100%;
$font-h1-medium-font-weight: Medium;
$font-h1-semibold-font-family: "Gellix SemiBold";
$font-h1-semibold-font-size: 60px;
$font-h1-semibold-line-height: 100%;
$font-h1-semibold-font-weight: SemiBold;
$font-h1-bold-font-family: "Gellix Bold";
$font-h1-bold-font-size: 60px;
$font-h1-bold-line-height: 100%;
$font-h1-bold-font-weight: Bold;
$font-h2-regular-font-family: "Gellix Regular";
$font-h2-regular-font-size: 27;
$font-h2-regular-line-height: 100%;
$font-h2-regular-font-weight: Regular;
$font-h2-medium-font-family: "Gellix Medium";
$font-h2-medium-font-size: 27;
$font-h2-medium-line-height: 100%;
$font-h2-medium-font-weight: Medium;
$font-h2-semibold-font-family: "Gellix SemiBold";
$font-h2-semibold-font-size: 27;
$font-h2-semibold-line-height: 100%;
$font-h2-semibold-font-weight: SemiBold;
$font-h2-bold-font-family: "Gellix Bold";
$font-h2-bold-font-size: 27;
$font-h2-bold-line-height: 100%;
$font-h2-bold-font-weight: Bold;
$font-h3-regular-font-family: "Gellix Regular";
$font-h3-regular-font-weight: Regular;
$font-h3-regular-line-height: 100%;
$font-h3-regular-font-size: 21px;
$font-h3-medium-font-family: "Gellix Medium";
$font-h3-medium-font-weight: Medium;
$font-h3-medium-line-height: 100%;
$font-h3-medium-font-size: 21px;
$font-h3-semibold-font-family: "Gellix SemiBold";
$font-h3-semibold-font-weight: SemiBold;
$font-h3-semibold-line-height: 100%;
$font-h3-semibold-font-size: 21px;
$font-h3-bold-font-family: "Gellix Bold";
$font-h3-bold-font-weight: Bold;
$font-h3-bold-line-height: 100%;
$font-h3-bold-font-size: 21px;
$font-h4-regular-font-family: "Gellix Regular";
$font-h4-regular-font-weight: Regular;
$font-h4-regular-line-height: 100%;
$font-h4-regular-font-size: 16px;
$font-h4-medium-font-family: "Gellix Medium";
$font-h4-medium-font-weight: Medium;
$font-h4-medium-line-height: 100%;
$font-h4-medium-font-size: 16px;
$font-h4-semibold-font-family: "Gellix SemiBold";
$font-h4-semibold-font-weight: SemiBold;
$font-h4-semibold-line-height: 100%;
$font-h4-semibold-font-size: 16px;
$font-h4-bold-font-family: "Gellix Bold";
$font-h4-bold-font-weight: Bold;
$font-h4-bold-line-height: 100%;
$font-h4-bold-font-size: 16px;
$font-default-regular-font-family: "Gellix Regular";
$font-default-regular-font-weight: Regular;
$font-default-regular-line-height: 130%;
$font-default-regular-font-size: 16px;
$font-default-medium-font-family: "Gellix Medium";
$font-default-medium-font-weight: Medium;
$font-default-medium-line-height: 130%;
$font-default-medium-font-size: 16px;
$font-default-semibold-font-family: "Gellix SemiBold";
$font-default-semibold-font-weight: SemiBold;
$font-default-semibold-font-size: 16px;
$font-default-semibold-line-height: 130%;
$font-default-bold-font-family: "Gellix Bold";
$font-default-bold-font-weight: Bold;
$font-default-bold-line-height: 130%;
$font-default-bold-font-size: 16px;
$font-small-regular-font-family: "Gellix Regular";
$font-small-regular-font-weight: Regular;
$font-small-regular-line-height: 130%;
$font-small-regular-font-size: 12.5px;
$font-small-medium-font-family: "Gellix Medium";
$font-small-medium-font-weight: Medium;
$font-small-medium-line-height: 130%;
$font-small-medium-font-size: 12.5px;
$font-small-semibold-font-family: "Gellix SemiBold";
$font-small-semibold-font-weight: SemiBold;
$font-small-semibold-line-height: 130%;
$font-small-semibold-font-size: 12.5px;
$font-small-bold-font-family: "Gellix Bold";
$font-small-bold-font-weight: Bold;
$font-small-bold-line-height: 130%;
$font-small-bold-font-size: 12.5px;
$font-mini-regular-font-family: "Gellix Regular";
$font-mini-regular-font-weight: Regular;
$font-mini-regular-line-height: 130%;
$font-mini-regular-font-size: 9px;
$font-mini-medium-font-family: "Gellix Medium";
$font-mini-medium-font-weight: Medium;
$font-mini-medium-line-height: 130%;
$font-mini-medium-font-size: 9px;
$font-mini-semibold-font-family: "Gellix SemiBold";
$font-mini-semibold-font-weight: SemiBold;
$font-mini-semibold-line-height: 130%;
$font-mini-semibold-font-size: 9px;
$font-mini-bold-font-family: "Gellix Bold";
$font-mini-bold-font-weight: Bold;
$font-mini-bold-line-height: 130%;
$font-mini-bold-font-size: 9px;
$space-12: 12;
$space-xs: 2px;
$space-s: 4px;
$space-m: 8px;
$space-l: 16px;
$space-xl: 20px;
$space-xxl: 24px;
$space-10radial: 10;
$border-navigation-color: #40566e;
$border-navigation-width: 1;
$border-navigation-style: solid;
$border-focus-color: #ff4027;
$border-focus-width: 1;
$border-focus-style: solid;
$border-focus-dash-color: #ff4027;
$border-focus-dash-width: 1;
$border-focus-dash-style: dashed;
$border-plan-tree-connector-color: #0c2340;
$border-plan-tree-connector-width: 1;
$border-plan-tree-connector-style: dashed;
$border-plan-tree-suggestor-color: #7c8c9c;
$border-plan-tree-suggestor-width: 1;
$border-plan-tree-suggestor-style: dashed;
$border-form-default-color: #7c8c9c;
$border-form-default-width: 1;
$border-form-default-style: solid;
$border-form-hover-color: #0c2340;
$border-form-hover-width: 1;
$border-form-hover-style: solid;
$border-form-selected-color: #0c2340;
$border-form-selected-width: 1;
$border-form-selected-style: solid;
$border-form-disabled-color: #b2b2b2;
$border-form-disabled-width: 1;
$border-form-disabled-style: solid;
$border-divider-color: #f2f2f2;
$border-divider-width: 1;
$border-divider-style: solid;
$border-calendar-color: #b2b2b2;
$border-calendar-width: 1;
$border-calendar-style: solid;
$border-white-outline-color: #ffffff;
$border-white-outline-width: 1;
$border-white-outline-style: solid;
$border-error-color: #c1272d;
$border-error-width: 1;
$border-error-style: solid;
$border-action-color: #7c8c9c;
$border-action-width: 1;
$border-action-style: solid;
$border-outline-tertiary-icon-color: #7c8c9c;
$border-outline-tertiary-icon-width: 1;
$border-outline-tertiary-icon-style: solid;
$border-outline-tertiary-icon-hover-color: #0c2340;
$border-outline-tertiary-icon-hover-width: 1;
$border-outline-tertiary-icon-hover-style: solid;
$border-primary-default-color: #0c2340;
$done-checkbox-color: #0b7be0;
$done-checkbox-width: 1;
$done-checkbox-style: solid;
$c10: 40px;
$c7: 28px;
$c5: 20px;
$c2: 8px;
$subtle-x: 0;
$subtle-y: 4;
$subtle-blur: 10;
$subtle-spread: 0;
$subtle-color: rgba(0, 0, 0, 0.06);
$subtle-type: dropShadow;
$c6: 24px;
