@import '../../../genericStylesSheet//variables.scss';
.contextMenuContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 8px;
  gap: 8px;
  position: absolute;
  width: $core-cx60;
  background: $color-background-primary;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25%), 0px 4px 4px rgba(0, 0, 0, 0.25%);
  z-index: 9999;
  cursor: pointer;
  visibility: hidden;
}
.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $space-s;
  height: 16px;
  padding: 8px;
  background: $color-background-primary;
  border-radius: $radius-m;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: $color-text-primary;
  cursor: pointer;

  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: $space-s;

    width: 166px;
    height: 15px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &:hover {
    background: $color-background-selected;
  }
  .menuTextNormal {
    height: 15px;
    font-family: $font-small-regular-font-family;
    font-size: $font-small-regular-font-size;
    line-height: $font-small-regular-font-weight;
    // color: $color-text-primary;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .menuTextGrey {
    height: 13px;
    font-family: $font-mini-regular-font-family;
    font-size: $font-mini-regular-font-size;
    line-height: $font-mini-regular-font-weight;
    color: $color-text-quaternary;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.primaryActionBackground {
  background: #0c2340;
  &:hover > .primaryActionMenuItem {
    color: $color-text-primary !important;
  }
}

.primaryActionBackgroundItem {
  background: #0c2340;
  color: white;
  &:hover {
    background: $color-background-selected !important;
    color: $color-text-primary !important;
  }
}

.primaryActionMenuItem {
  color: white;
}

.colorChange {
  position: relative;
}
.actionDivider {
  margin: 5px 0 5px 0;
}

.menuIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  svg {
    width: 22px;
    height: 22px;
  }
}

.writeNoteMenu {
  display: flex;
  flex-direction: column;
  padding: 0px 8px 2px px;
  // gap: 4px;
  background: $color-background-primary;
  border-radius: 8px;
  order: 2;
  flex-grow: 0;
  .writeNoteMenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 0px 2px 0px;
    gap: 4px;

    width: 166px;
    height: 15px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
.writeNoteMenuTextNormal {
  height: 20px;
  font-family: $font-small-regular-font-family;
  font-size: $font-small-regular-font-size;
  line-height: $font-small-regular-line-height;
  // color: #000000;
}

#fullWidth {
  font-family: $font-small-regular-font-family;
  font-size: $font-small-regular-font-size;
  line-height: $font-small-regular-line-height;
}

.writeNoteMenuTextGrey {
  height: 13px;
  font-family: $font-mini-regular-font-family;
  font-size: $font-mini-regular-font-size;
  line-height: $font-mini-regular-font-weight;
  color: #ceced0;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.actionMenuIcon {
  color: $color-text-inverse;
  line-height: 0.2;
}

.actionMenuIconBlack {
  color: #000;
  line-height: 0.2;
}
.actioncommon {
  position: 'absolute';
  box-shadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px';
  padding: '8px';
  background-color: $color-background-primary;
}
.actionContextMenuContainer {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 8px;
  background-color: $color-background-primary;
  width: $core-cx60;
}

.queueContextMenuContainer {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 8px;
  background-color: $color-background-primary;
  width: $core-cx60;
}
.menuitemDisable {
  background-color: $color-background-secondary;
  cursor: default;
  text-align: center;
  &:hover {
    background: $color-background-secondary;
  }
}
.menuItemNogoalText {
  width: 100%;
  height: auto !important;
}
.menuitemNoGoal {
  height: 32px !important;
  cursor: default;
  width: 320px;
}
.scheduleSaveBtn {
  color: $color-text-inverse !important;
  background: $color-action-primary-default !important;
  border-radius: $radius-circle !important;
  width: 77px;
  height: 32px;
  padding-top: $space-m;
  padding-bottom: $space-m;
  padding-left: $space-xxl;
  padding-right: $space-xxl;
  text-transform: none !important;  
  font-family: $font-small-medium-font-family !important;
  font-size: $font-small-medium-font-size !important;
  font-weight: $font-small-medium-font-weight !important;
}
.scheduleRemoveBtn {
  color: $color-action-secondary-inverse !important;
  width: 100%;
  height: 32px;
  border: 1px solid var(--color-action-tertiary-hover-fg, #0c2340);
  background: var(--color-action-secondary-default, #FFF);
  border-radius: $radius-circle !important;
  padding-top: $space-m;
  padding-bottom: $space-m;
  padding-left: $space-xxl;
  padding-right: $space-xxl;
  text-transform: none !important;  
  font-family: $font-small-medium-font-family !important;
  font-size: $font-small-medium-font-size !important;
  line-height: 1;
}


.formRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.repeatBtnGroup {
  box-shadow: none !important;
}
.repeatBtnGroup :global(button:nth-child(2)) {
  padding: 8px 16px;
  width: 90px;
  height: 31px;
  background: $color-scales-secondary-100;
  border-radius: 0px 24px 24px 0px;
  color: $color-text-primary;
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
}
.repeatBtnGroup :global(button:first-child) {
  padding: 8px 16px;
  width: 90px;
  height: 31px;
  background: $color-scales-secondary-100;
  border-radius: 24px 0px 0px 24px;
  color: $color-text-primary;
  font-weight: 400;
  font-size: 12px;
  border-color: #ccc !important;
  text-transform: none;
}
.repeatBtnGroup :global(button.selected) {
  background: $color-background-selected !important;
  border: 1px solid #0c2340 !important;
}

.formDateContainer :global(.MuiInputBase-root) {
  width: 80% !important;
  height: 24px;
  background: $color-background-primary;
  border: $border-form-default-width $border-form-default-style $border-form-default-color;
  border-radius: $radius-l;
  overflow: hidden;
}
.formDateContainer :global(.Mui-error) {
  border: 1px solid #7c8c9c !important;
}
.formDateContainer :global(.MuiTextField-root) {
  min-width: 290px !important;
}

.formDateContainer :global(.MuiFormControl-root) {
  min-width: 290px !important;
}
.formSelectContainer {
  width: 100% !important;
  height: 24px;
  background: $color-background-primary;
  border: 1px solid #7c8c9c;
  border-radius: 12px !important;
  overflow: hidden;
}

.hideMoreOption {
  display: none !important;
}
.visibleMoreOption {
  display: block;
}
.actionsContainer {
  svg {
    color: #000;
  }
  button:not(#moreOptionsButton):hover {
    background-color: transparent;
  }
}

#moreOptionsButton:hover {
  cursor: pointer !important;
}

.actionsCalendarContainer {
  svg {
    color: $color-text-inverse;
  }
  button:hover {
    background-color: #dadadc;
    svg {
      color: #000;
    }
  }
}

.scheduleCancelBtn {
  padding: 8px 24px !important;
  width: 90px;
  height: 32px;
  background: #ffffff;
  border: 1px solid $border-form-selected-color !important;
  border-radius: 1000px !important;
  color: $color-text-primary !important;
}

.buttonsWrapperFlexDiv {
  display: flex;
  flex-direction: row;
  gap: $space-m;
  align-items: flex-start;
}

.scheduleActionPopup {
  width: $core-cx69;
  padding: 16px;
}

.actionPositionInQueueText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionPositionInQueueText > span {
  font-size: 10px !important;
}


.actionPositionInQueueTextMenuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 8px;
}

.repetitionBtn {
  height: 28px !important;
  font-family: $font-small-medium-font-family;
  font-size: $font-small-medium-font-size;
  line-height: $font-small-medium-line-height;
}

.repetitionInputLabel {
  font-family: $font-small-medium-font-family;
  font-size: $font-small-medium-font-size;
  line-height: $font-small-medium-line-height;
}

.divider {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  order: 2;
  width: 100%;

  hr {
    width: 100%;
  }
}

.menuItemDisclaimer {
  &:hover {
    background: transparent !important;
    cursor: default !important;
  }
}
